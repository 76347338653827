<template>
  <div class="management_edit">
    <div class="content">
      <span class="title">{{routeData.id?'编辑':'添加'}}企业</span>
      <el-form ref="form" :rules="rules" :model="form" class="info">
        <el-form-item prop="name" class="item">
          <label>企业名称</label>
          <el-input v-model="form.name" clearable/>
        </el-form-item>
        <el-form-item prop="province" class="item">
          <label>所属省</label>
          <el-select v-model="form.province" @change="form.city=''">
            <el-option v-for="(val,key,index) in provinceList" :key="index" :label="val" :value="val"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="city" class="item">
          <label>所属市</label>
          <el-select v-model="form.city">
            <el-option v-for="(val,key,index) in cityList" :key="index" :label="val" :value="val"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="website" class="item">
          <label>用户状态：</label>
           <el-switch
              v-model="form.status"
              active-color="#2DEB79"
              inactive-color="#B9B9B9"
              :active-value="0"
              :inactive-value="1"
            />
          <span style="margin-left:10px;color:#B9B9B9">{{form.status==0?'启用':'禁用'}}</span>
        </el-form-item>
        <el-form-item prop="managerName" class="item" v-if="!routeData.id">
          <label>管理员姓名</label>
          <el-input v-model="form.managerName" clearable/>
        </el-form-item>
        <el-form-item prop="managerPhone" class="item" v-if="!routeData.id">
          <label>管理员手机号</label>
          <el-input v-model="form.managerPhone" clearable/>
        </el-form-item>
      </el-form>
      <div class="btnList" v-if="routeData.id">
        <el-button type="primary" size="small" @click="checkFormSubmit(1)">确认</el-button>
      </div>
    </div>
    <div class="power">
       <span class="title">模块权限分配</span>
       <div class="powerItems">
        <div class="item" v-for="item in powerLists" :key="item.moduleName">
          <img :src="item.icon"/>
          <span>{{item.moduleName}}</span>
          <el-checkbox v-model="item.checked" @click.native="routeData.id ? changeModules($event,item) : null"/>
          <div v-if="item.showDetails" class="authorize">
            <div class="grid">
              <span class="label">有效期：</span>
              <el-date-picker
                style="width:100%"
                v-model="item.validDate"
                type="daterange"
                range-separator="至"
                start-placeholder="起始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                >
              </el-date-picker>
            </div>
            <div class="grid">
              <label class="label">授权人数：</label>
              <el-input size="small" v-model="item.authorizeUserLimit"/>
            </div>
            <el-button v-if="routeData.id" size="small" type="primary" @click="changeModules(null,item)">确认</el-button>
          </div>
        </div>
       </div>
    </div>
    <div class="footer" v-if="!routeData.id">
      <el-button size="small" @click="goRoute">取消</el-button>
      <el-button size="small" type="primary" @click="routeData.id?goRoute():checkFormSubmit(2)">确定</el-button>
    </div>
  </div>
</template>

<script>
import { 
  getEnterpriseDetail,
  getEnterpriseModule,
  getModuleList,
  authorizeModule,
  disableModule,
  addEnterprise,
  saveEnterprise,
  addEnterpriseModule
} from '@/api/management'
import { cityJson } from '@/utils/city.js'
export default {
  name: "management_edit",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      rules:{
        name: [
          { required: true, message: '请输入企业名称', trigger: ['blur','change'] },
        ],
        province:[
          { required: true, message: '请选择所属省', trigger: ['blur','change'] },
        ],
        city:[
          { required: true, message: '请选择所属市', trigger: ['blur','change'] },
        ],
        managerName: [
          { required: true, message: '请输入管理员姓名', trigger: ['blur','change'] }
        ],
        managerPhone:[
          { required: true, message: '请输入管理员手机号', trigger: ['blur','change'] },
          { pattern:new RegExp("^(?:(?:\\+|00)86)?1[3-9]\\d{9}$|^0\\d{2,3}-?\\d{7,8}$"), message: "请输入合法手机号/电话号", trigger: ['blur', 'change'] }
        ],
      },
      routeData:{},
      form:{
        name:'',
        province:'',
        city:'',
        status:1,
        managerPhone:'',
        managerName:''
      },
      provinceList:cityJson[0],
      cityList:[],
      powerLists:[
        {
          id:1,
          icon:require('@/assets/images/common/tos.png'),
          moduleName:'机器人调度',
          checked:false,
          showDetails:false,
          validDate:null,
          authorizeUserLimit:999
        },{
          id:2,
          icon:require('@/assets/images/common/xos.png'),
          moduleName:'XOS管理',
          checked:false,
          showDetails:false,
          validDate:null,
          authorizeUserLimit:999
        },{
          id:3,
          icon:require('@/assets/images/common/aps.png'),
          moduleName:'APS先进排程',
          checked:false,
          showDetails:true,
          validDate:null,
          authorizeUserLimit:''
        },{
          id:4,
          icon:require('@/assets/images/common/mes.png'),
          moduleName:'MES',
          checked:false,
          showDetails:true,
          validDate:null,
          authorizeUserLimit:''
        },{
          id:5,
          icon:require('@/assets/images/common/wms.png'),
          moduleName:'WMS',
          checked:false,
          showDetails:true,
          validDate:null,
          authorizeUserLimit:''
        }
      ],
    };
  },
  watch:{
    'form.province':{
      handler(val){
        if(val){
          this.cityList = cityJson[this.findKey(cityJson[0],val)]
        }else{
          this.cityList = []
        }
      }
    }
  },
  async activated() {
    this.routeData = this.$route.query
    this.getModuleLists()
    if(this.routeData.id){
      this.form = {
        ...this.routeData,
        status:Number(this.routeData.status)
      }
      this.getDetails()
      this.getModules()
    }
  },
  deactivated(){
    // Object.assign(this.form,this.$options.data().form)
    Object.assign(this.$data,this.$options.data())
    this.$nextTick(()=>{
      this.$refs.form.clearValidate()
    })
  },
  methods:{
    goRoute(){
      this.$router.go(-1)
    },
    findKey(obj, value, compare = (a, b) => a === b) {
      return Object.keys(obj).find(k => compare(obj[k], value))
    },
    //获取企业详情
    getDetails(){
      let params = {
        orgId:this.routeData.id
      }
      getEnterpriseDetail(params).then(res=>{
        if(res.code==200){
          this.form = res.data
        }
      })
    },
    // 获取所有模块列表
    getModuleLists(){
      let params = {
        orgId:this.routeData.id
      }
      getModuleList(params).then(res=>{
        if(res.code==200){
          res.data.forEach((item,index)=>{
            let data = {
              ...this.powerLists[index],
              ...item
            }
            this.$set(this.powerLists,index,data)
          })
        }
      })
    },
    // 获取授权模块列表
    getModules(){
      let params = {
        orgId:this.routeData.id
      }
      getEnterpriseModule(params).then(res=>{
        if(res.code==200){
          res.data.forEach((item)=>{
            let index = item.moduleId - 1
            this.$set(this.powerLists[index],'checked',true)
            let module = ['APS先进排程','MES','WMS']
            if(module.includes(item.moduleName)){
              this.$set(this.powerLists[index],'validDate',[item.authorizeStartTime,item.authorizeEndTime])
              this.$set(this.powerLists[index],'authorizeUserLimit',item.authorizeUserLimit)
            }
          })
        }
      })
    },
    // 提交校验
    checkFormSubmit(type){
       this.$refs.form.validate((valid) => {
          if (valid) {
            type == 1 ? this.saveInfo() : this.addEnterpriseWithPower()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    // 保存客户信息
    saveInfo(){
      let Api = this.routeData.id? saveEnterprise : addEnterprise
      let {name,province,city,status} = this.form
      let params = {
        id: this.routeData.id,
        name,
        province,
        city,
        address:`${province}${city}`,
        status
      }
      Api(params).then(res=>{
        if(res.code==200){
          this.$message({
            type:'success',
            message: '保存成功'
          })
        }
      })
    },
    // 修改授权模块
    changeModules(event,item){
      let module = ['APS先进排程','MES','WMS']
      if(event && (module.includes(item.moduleName) || event?.target['_prevClass']!="el-checkbox__inner")){
        return
      }
      // if(event.target['_prevClass']!="el-checkbox__inner") return
      let  value = item.checked
      let { validDate , authorizeUserLimit } = this.powerLists[item.id-1]
      let params = {
        orgId:this.routeData.id,
        moduleId:item.moduleId || item.id,
      }
      if(module.includes(item.moduleName)){
        if(!validDate){
          // this.$set(this.powerLists[item.id - 1],'checked',!value)
          return this.$message.warning('请先选择有效日期')
        }
        if(!authorizeUserLimit){
          // this.$set(this.powerLists[item.id - 1],'checked',!value)
          return this.$message.warning('请先填写授权人数')
        }
        params.authorizeStartTime = validDate[0]
        params.authorizeEndTime = validDate[1]
        params.authorizeUserLimit = authorizeUserLimit
        this.submitModule(value,params)
      }else{
        this.submitModule(!value,params)
      }
    },
    submitModule(value,params){
      let Api = value ? authorizeModule : disableModule
      Api(params).then(res=>{
        if(res.code==200){
          this.$message({
            type:'success',
            message: `授权${value ? '分配' : '禁止'}成功`
          })
        }else{
          this.$message.error(res.msg || '操作失败，请重试')
        }
      })
    },
    //添加企业以及企业权限
    addEnterpriseWithPower(){
      let authorizes = []
      let powerLists = this.powerLists.filter(v=>v.checked)//勾选的模块
      let module = ['APS先进排程','MES','WMS']
      let checkPower = true;
      powerLists.forEach((item)=>{
        if(module.includes(item.moduleName)){
          if(!item.validDate){
            checkPower = false
            return this.$message.warning(`请先选择${item.moduleName}有效日期`)
        }
          if(!item.authorizeUserLimit){
            checkPower = false
            return this.$message.warning(`请先填写${item.moduleName}授权人数`)
          }
        }
        authorizes.push({
          orgId:this.routeData.id,
          moduleId:item.id,
          authorizeStartTime:item.validDate?.[0],
          authorizeEndTime:item.validDate?.[1],
          authorizeUserLimit:Number(item.authorizeUserLimit)
        })
      })
      if(!checkPower) return
      let {name,province,city,status,managerName,managerPhone} = this.form;
      let params = {
        name,
        province,
        city,
        address:`${province}${city}`,
        status,
        authorizes:authorizes,
        managerName,
        managerPhone
      }
      addEnterpriseModule(params).then(res=>{
        if(res.code==200){
          this.$message({
            type:'success',
            message: '添加成功'
          })
          this.$router.go(-1)
        }else{
          this.$message.error(res.msg || '操作失败，请重试')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.management_edit{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 180px);
  text-align: left;
  color: #E8E8E8;
  .title{
    font-size: 20px;
  }
  .content{
    background: #262626;
    border-radius: 8px;
    padding: 23px 39px;
    .info{
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      .item{
        width: 18%;
        margin-right: 100px;
        .el-select{
          width: 100%;
        }
        label{
          display: block;;
        }
      }
    }
    .btnList{
      margin-top: 20px;
    }
  }
  .power{
    background: #262626;
    border-radius: 8px;
    padding: 23px 39px;
    margin-top: 20px;
    .powerItems{
      display:flex;
      justify-content: space-between;
      margin: 30px 0px 20px 0px;
      .item{
        vertical-align: middle;
        img{
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
        span{
          margin: 0px 10px;
        }
        .authorize{
          .grid{
            margin-top: 10px;
            display:grid;
            grid-auto-rows: 50px;
            grid-template-columns: 100px 250px;
          }
        }
      }
    }
  }
  .footer{
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    line-height: 60px;
    background: #262626;
    margin: 0px 2px;
    padding-right: 32px;
  }
}
</style>
<style>
.el-date-table td.in-range div{
  background:#6e8e75
}
.el-date-range-picker__time-header{
  display: none;
}
.el-date-editor .el-range-input{
  background: none;
}
.el-form-item.is-error .el-input__inner{
    border-color: #F56C6C!important;
  }
</style>
