export const cityJson = {
  0: {
    1: '北京',
    2: '天津',
    3: '河北省',
    4: '山西省',
    5: '内蒙古自治区',
    6: '辽宁省',
    7: '吉林省',
    8: '黑龙江省',
    9: '上海',
    10: '江苏省',
    11: '浙江省',
    12: '安徽省',
    13: '福建省',
    14: '江西省',
    15: '山东省',
    16: '河南省',
    17: '湖北省',
    18: '湖南省',
    19: '广东省',
    20: '广西壮族自治区',
    21: '海南省',
    22: '重庆',
    23: '四川省',
    24: '贵州省',
    25: '云南省',
    26: '西藏自治区',
    27: '陕西省',
    28: '甘肃省',
    29: '青海省',
    30: '宁夏回族自治区',
    31: '新疆维吾尔自治区',
    32: '台湾',
    33: '香港特别行政区',
    34: '澳门特别行政区',
    35: '海外'
  },
  1: {
    36: '北京市'
  },
  2: {
    37: '天津市'
  },
  3: {
    38: '石家庄市',
    39: '唐山市',
    40: '秦皇岛市',
    41: '邯郸市',
    42: '邢台市',
    43: '保定市',
    44: '张家口市',
    45: '承德市',
    46: '沧州市',
    47: '廊坊市',
    48: '衡水市'
  },
  4: {
    49: '太原市',
    50: '大同市',
    51: '阳泉市',
    52: '长治市',
    53: '晋城市',
    54: '朔州市',
    55: '晋中市',
    56: '运城市',
    57: '忻州市',
    58: '临汾市',
    59: '吕梁市'
  },
  5: {
    60: '呼和浩特市',
    61: '包头市',
    62: '乌海市',
    63: '赤峰市',
    64: '通辽市',
    65: '鄂尔多斯市',
    66: '呼伦贝尔市',
    67: '巴彦淖尔市',
    68: '乌兰察布市',
    69: '兴安盟',
    70: '锡林郭勒盟',
    71: '阿拉善盟'
  },
  6: {
    72: '沈阳市',
    73: '大连市',
    74: '鞍山市',
    75: '抚顺市',
    76: '本溪市',
    77: '丹东市',
    78: '锦州市',
    79: '营口市',
    80: '阜新市',
    81: '辽阳市',
    82: '盘锦市',
    83: '铁岭市',
    84: '朝阳市',
    85: '葫芦岛市'
  },
  7: {
    86: '长春市',
    87: '吉林市',
    88: '四平市',
    89: '辽源市',
    90: '通化市',
    91: '白山市',
    92: '松原市',
    93: '白城市',
    94: '延边朝鲜族自治州'
  },
  8: {
    95: '哈尔滨市',
    96: '齐齐哈尔市',
    97: '鸡西市',
    98: '鹤岗市',
    99: '双鸭山市',
    100: '大庆市',
    101: '伊春市',
    102: '佳木斯市',
    103: '七台河市',
    104: '牡丹江市',
    105: '黑河市',
    106: '绥化市',
    107: '大兴安岭地区'
  },
  9: {
    108: '上海市'
  },
  10: {
    109: '南京市',
    110: '无锡市',
    111: '徐州市',
    112: '常州市',
    113: '苏州市',
    114: '南通市',
    115: '连云港市',
    116: '淮安市',
    117: '盐城市',
    118: '扬州市',
    119: '镇江市',
    120: '泰州市',
    121: '宿迁市'
  },
  11: {
    122: '杭州市',
    123: '宁波市',
    124: '温州市',
    125: '嘉兴市',
    126: '湖州市',
    127: '绍兴市',
    128: '金华市',
    129: '衢州市',
    130: '舟山市',
    131: '台州市',
    132: '丽水市'
  },
  12: {
    133: '合肥市',
    134: '芜湖市',
    135: '蚌埠市',
    136: '淮南市',
    137: '马鞍山市',
    138: '淮北市',
    139: '铜陵市',
    140: '安庆市',
    141: '黄山市',
    142: '滁州市',
    143: '阜阳市',
    144: '宿州市',
    145: '六安市',
    146: '亳州市',
    147: '池州市',
    148: '宣城市'
  },
  13: {
    149: '福州市',
    150: '厦门市',
    151: '莆田市',
    152: '三明市',
    153: '泉州市',
    154: '漳州市',
    155: '南平市',
    156: '龙岩市',
    157: '宁德市'
  },
  14: {
    158: '南昌市',
    159: '景德镇市',
    160: '萍乡市',
    161: '九江市',
    162: '新余市',
    163: '鹰潭市',
    164: '赣州市',
    165: '吉安市',
    166: '宜春市',
    167: '抚州市',
    168: '上饶市'
  },
  15: {
    169: '济南市',
    170: '青岛市',
    171: '淄博市',
    172: '枣庄市',
    173: '东营市',
    174: '烟台市',
    175: '潍坊市',
    176: '济宁市',
    177: '泰安市',
    178: '威海市',
    179: '日照市',
    180: '莱芜市',
    181: '临沂市',
    182: '德州市',
    183: '聊城市',
    184: '滨州市',
    185: '菏泽市'
  },
  16: {
    186: '郑州市',
    187: '开封市',
    188: '洛阳市',
    189: '平顶山市',
    190: '安阳市',
    191: '鹤壁市',
    192: '新乡市',
    193: '焦作市',
    194: '濮阳市',
    195: '许昌市',
    196: '漯河市',
    197: '三门峡市',
    198: '南阳市',
    199: '商丘市',
    200: '信阳市',
    201: '周口市',
    202: '驻马店市'
  },
  17: {
    203: '武汉市',
    204: '黄石市',
    205: '十堰市',
    206: '宜昌市',
    207: '襄阳市',
    208: '鄂州市',
    209: '荆门市',
    210: '孝感市',
    211: '荆州市',
    212: '黄冈市',
    213: '咸宁市',
    214: '随州市',
    215: '恩施土家族苗族自治州'
  },
  18: {
    216: '长沙市',
    217: '株洲市',
    218: '湘潭市',
    219: '衡阳市',
    220: '邵阳市',
    221: '岳阳市',
    222: '常德市',
    223: '张家界市',
    224: '益阳市',
    225: '郴州市',
    226: '永州市',
    227: '怀化市',
    228: '娄底市',
    229: '湘西土家族苗族自治州'
  },
  19: {
    230: '广州市',
    231: '韶关市',
    232: '深圳市',
    233: '珠海市',
    234: '汕头市',
    235: '佛山市',
    236: '江门市',
    237: '湛江市',
    238: '茂名市',
    239: '肇庆市',
    240: '惠州市',
    241: '梅州市',
    242: '汕尾市',
    243: '河源市',
    244: '阳江市',
    245: '清远市',
    246: '东莞市',
    247: '中山市',
    248: '东沙群岛',
    249: '潮州市',
    250: '揭阳市',
    251: '云浮市'
  },
  20: {
    252: '南宁市',
    253: '柳州市',
    254: '桂林市',
    255: '梧州市',
    256: '北海市',
    257: '防城港市',
    258: '钦州市',
    259: '贵港市',
    260: '玉林市',
    261: '百色市',
    262: '贺州市',
    263: '河池市',
    264: '来宾市',
    265: '崇左市'
  },
  21: {
    266: '海口市',
    267: '三亚市',
    268: '三沙市'
  },
  22: {
    269: '重庆市'
  },
  23: {
    270: '成都市',
    271: '自贡市',
    272: '攀枝花市',
    273: '泸州市',
    274: '德阳市',
    275: '绵阳市',
    276: '广元市',
    277: '遂宁市',
    278: '内江市',
    279: '乐山市',
    280: '南充市',
    281: '眉山市',
    282: '宜宾市',
    283: '广安市',
    284: '达州市',
    285: '雅安市',
    286: '巴中市',
    287: '资阳市',
    288: '阿坝藏族羌族自治州',
    289: '甘孜藏族自治州',
    290: '凉山彝族自治州'
  },
  24: {
    291: '贵阳市',
    292: '六盘水市',
    293: '遵义市',
    294: '安顺市',
    295: '铜仁市',
    296: '黔西南布依族苗族自治州',
    297: '毕节市',
    298: '黔东南苗族侗族自治州',
    299: '黔南布依族苗族自治州'
  },
  25: {
    300: '昆明市',
    301: '曲靖市',
    302: '玉溪市',
    303: '保山市',
    304: '昭通市',
    305: '丽江市',
    306: '普洱市',
    307: '临沧市',
    308: '楚雄彝族自治州',
    309: '红河哈尼族彝族自治州',
    310: '文山壮族苗族自治州',
    311: '西双版纳傣族自治州',
    312: '大理白族自治州',
    313: '德宏傣族景颇族自治州',
    314: '怒江傈僳族自治州',
    315: '迪庆藏族自治州'
  },
  26: {
    316: '拉萨市',
    317: '昌都市',
    318: '山南地区',
    319: '日喀则市',
    320: '那曲地区',
    321: '阿里地区',
    322: '林芝市'
  },
  27: {
    323: '西安市',
    324: '铜川市',
    325: '宝鸡市',
    326: '咸阳市',
    327: '渭南市',
    328: '延安市',
    329: '汉中市',
    330: '榆林市',
    331: '安康市',
    332: '商洛市'
  },
  28: {
    333: '兰州市',
    334: '嘉峪关市',
    335: '金昌市',
    336: '白银市',
    337: '天水市',
    338: '武威市',
    339: '张掖市',
    340: '平凉市',
    341: '酒泉市',
    342: '庆阳市',
    343: '定西市',
    344: '陇南市',
    345: '临夏回族自治州',
    346: '甘南藏族自治州'
  },
  29: {
    347: '西宁市',
    348: '海东市',
    349: '海北藏族自治州',
    350: '黄南藏族自治州',
    351: '海南藏族自治州',
    352: '果洛藏族自治州',
    353: '玉树藏族自治州',
    354: '海西蒙古族藏族自治州'
  },
  30: {
    355: '银川市',
    356: '石嘴山市',
    357: '吴忠市',
    358: '固原市',
    359: '中卫市'
  },
  31: {
    360: '乌鲁木齐市',
    361: '克拉玛依市',
    362: '吐鲁番市',
    363: '哈密地区',
    364: '昌吉回族自治州',
    365: '博尔塔拉蒙古自治州',
    366: '巴音郭楞蒙古自治州',
    367: '阿克苏地区',
    368: '克孜勒苏柯尔克孜自治州',
    369: '喀什地区',
    370: '和田地区',
    371: '伊犁哈萨克自治州',
    372: '塔城地区',
    373: '阿勒泰地区'
  },
  32: {
    374: '台北市',
    375: '高雄市',
    376: '台南市',
    377: '台中市',
    378: '金门县',
    379: '南投县',
    380: '基隆市',
    381: '新竹市',
    382: '嘉义市',
    383: '新北市',
    384: '宜兰县',
    385: '新竹县',
    386: '桃园县',
    387: '苗栗县',
    388: '彰化县',
    389: '嘉义县',
    390: '云林县',
    391: '屏东县',
    392: '台东县',
    393: '花莲县',
    394: '澎湖县',
    395: '连江县'
  },
  33: {
    396: '香港岛',
    397: '九龙',
    398: '新界'
  },
  34: {
    399: '澳门半岛',
    400: '离岛'
  },
  35: {
    401: '海外'
  }
}