// 企业管理
import {get,put,post,del} from '@/utils/http.js'

export function qiyecustomer() {
    return get('/customer/page')
}
//获取企业列表
export function getEnterprise(params) {
    return get('/customer/page',params)
}
//获取企业详情
export function getEnterpriseDetail(params) {
    return get('/customer/detail/to-manufacturer',params)
}
//获取企业已授权列表
export function getEnterpriseModule(params) {
    return get('/customer/module/list/to-manufacturer',params)
}
//获取所有模块列表
export function getModuleList(){
    return get('/module/list')
}
//授权分配
export function authorizeModule(params) {
    return put('/customer/module/authorize',params)
}
//授权禁止
export function disableModule(params) {
    return put('/customer/module/prohibit',params)
}
//添加企业
export function addEnterprise(params) {
    return post('/customer',params)
}
//修改企业信息
export function saveEnterprise(params) {
    return put('/customer',params)
}
//添加企业以及企业权限
export function addEnterpriseModule(params) {
    return post('/customer/with-module',params)
}
//删除企业
export function deleteCustomer(id) {
    return del(`/customer/${id}`)
  }